<template>
  <div class="sign-wrap" v-loading="loading" element-loading-text="加载中...">
    <!--    <Search/>-->
    <!--    <el-button class="add-sign" @click="dialogVisible=true">新增签名</el-button>-->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="ID" label="签名ID" width="180"></el-table-column>
      <el-table-column prop="Name" label="签名名称" width="180"></el-table-column>
      <el-table-column prop="CreatedAt" label="申请时间"></el-table-column>
      <el-table-column prop="Status" label="状态">
        <template #default="scope">
          {{ signObj[scope.row.Status] }}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--          fixed="right"-->
      <!--          label="操作"-->
      <!--          width="100">-->
      <!--        <template #default="scope">-->
      <!--          <el-button type="text" size="small" @click="seeDialogVisible= true">查看</el-button>-->
      <!--          <el-button @click="handleClick(scope.row)" type="text" size="small">删除</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
      class="page"
      @current-change="onChangePage"
      v-model:current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <el-dialog title="新增签名" v-model="dialogVisible" width="40%" :before-close="handleClose">
      <el-form>
        <el-row>
          <el-form-item label="设置签名" label-width="100px">
            <el-input v-model="name" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="签名用途" label-width="100px">
            <el-radio v-model="region" label="0">自用</el-radio>
            <el-radio v-model="region" label="1">他用</el-radio>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="营业" label-width="100px">
            <el-upload
              class="change-width"
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="签名有效期" label-width="100px">
            <el-row>
              <el-col :span="16">
                <el-date-picker
                  size="small"
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-radio v-model="radio" label="1">长期</el-radio>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <template v-if="region === '1'">
          <el-row>
            <el-form-item label="委托" label-width="100px">
              <el-upload
                class="change-width"
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="委托执照" label-width="100px">
              <el-upload
                class="change-width"
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="委托签名有效期" label-width="100px">
              <el-row>
                <el-col :span="16">
                  <el-date-picker
                    size="small"
                    v-model="date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-col>
                <el-col :span="6">
                  <el-radio v-model="radio" label="1">长期</el-radio>
                </el-col>
              </el-row>
            </el-form-item>
          </el-row>
        </template>
        <el-row>
          <el-form-item label="其他证明信息" label-width="100px">
            <el-upload
              class="change-width"
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="备注" label-width="100px">
            <el-input type="textarea" autosize placeholder="请填写备注" />
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="查看签名" v-model="seeDialogVisible" width="40%">
      <el-form>
        <el-row>
          <el-form-item label="设置签名" label-width="100px">
            <el-input v-model="name" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="签名用途" label-width="100px">
            <el-radio v-model="region" label="0">自用</el-radio>
            <el-radio v-model="region" label="1">他用</el-radio>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="营业" label-width="100px">
            <el-upload
              class="change-width"
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="签名有效期" label-width="100px">
            <el-row>
              <el-col :span="16">
                <el-date-picker
                  size="small"
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-radio v-model="radio" label="1">长期</el-radio>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <template v-if="region === '1'">
          <el-row>
            <el-form-item label="委托" label-width="100px">
              <el-upload
                class="change-width"
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="委托执照" label-width="100px">
              <el-upload
                class="change-width"
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="委托签名有效期" label-width="100px">
              <el-row>
                <el-col :span="16">
                  <el-date-picker
                    size="small"
                    v-model="date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-col>
                <el-col :span="6">
                  <el-radio v-model="radio" label="1">长期</el-radio>
                </el-col>
              </el-row>
            </el-form-item>
          </el-row>
        </template>
        <el-row>
          <el-form-item label="其他证明信息" label-width="100px">
            <el-upload
              class="change-width"
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="备注" label-width="100px">
            <el-input type="textarea" autosize placeholder="请填写备注" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="操作" label-width="100px">
            <el-radio-group v-model="checkStatus">
              <el-radio :label="0">通过</el-radio>
              <el-radio :label="1">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row v-if="checkStatus === 1">
          <el-form-item label="不通过理由" label-width="100px">
            <el-input type="textarea" autosize placeholder="请填写不通过理由" />
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="seeDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="seeDialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import axios from 'axios'
import { SIGN_OBJ } from '@/config/constEnum'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  name: 'Sign',
  setup() {
    const name = ref('')
    const region = ref(0)
    const date = ref('')
    const radio = ref()
    const checkStatus = ref(0)

    const total = ref(0)
    const pageSize = 10
    const page = ref(1)

    const tableData = ref([])
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const loading = ref(false)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const fileList = [
      {
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      },
      {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }
    ]
    const handleRemove = (file: any, fileList: any) => {
      console.log(file, fileList)
    }

    const getList = () => {
      loading.value = true
      const reqData = {
        status: 'all',
        size: pageSize,
        page: page.value
      }
      axios
        .get('/-/user/sign/list', { params: reqData })
        .then(res => {
          const data = res.data.msg
          total.value = data.total
          tableData.value = data.signs
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onChangePage = (val: any) => {
      page.value = val
      getList()
    }

    onMounted(() => {
      getList()
    })
    return {
      name,
      date,
      page,
      radio,
      total,
      region,
      loading,
      pageSize,
      fileList,
      tableData,
      checkStatus,
      handleClose,
      handleRemove,
      onChangePage,
      dialogVisible,
      seeDialogVisible,
      signObj: SIGN_OBJ
    }
  }
})
</script>
<style lang="less" scoped>
.sign-wrap {
  position: relative;
  background-color: #f2f2f2;

  .add-sign {
    top: 20px;
    position: absolute;
    right: 20px;
  }

  .el-upload-list {
    display: inline-block;
    vertical-align: middle;
  }

  .page {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
<style scoped>
.el-upload {
  display: inline-block;
  vertical-align: middle;
}
.upload-demo >>> .el-upload-list {
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.change-width >>> .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-col >>> .el-range-editor.el-input__inner {
  width: 100%;
}
</style>
