
import axios from 'axios'
import { SIGN_OBJ } from '@/config/constEnum'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  name: 'Sign',
  setup() {
    const name = ref('')
    const region = ref(0)
    const date = ref('')
    const radio = ref()
    const checkStatus = ref(0)

    const total = ref(0)
    const pageSize = 10
    const page = ref(1)

    const tableData = ref([])
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const loading = ref(false)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const fileList = [
      {
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      },
      {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }
    ]
    const handleRemove = (file: any, fileList: any) => {
      console.log(file, fileList)
    }

    const getList = () => {
      loading.value = true
      const reqData = {
        status: 'all',
        size: pageSize,
        page: page.value
      }
      axios
        .get('/-/user/sign/list', { params: reqData })
        .then(res => {
          const data = res.data.msg
          total.value = data.total
          tableData.value = data.signs
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onChangePage = (val: any) => {
      page.value = val
      getList()
    }

    onMounted(() => {
      getList()
    })
    return {
      name,
      date,
      page,
      radio,
      total,
      region,
      loading,
      pageSize,
      fileList,
      tableData,
      checkStatus,
      handleClose,
      handleRemove,
      onChangePage,
      dialogVisible,
      seeDialogVisible,
      signObj: SIGN_OBJ
    }
  }
})
